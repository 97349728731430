import { IInventoryContainer, IInventoryContainerType } from '@/features/Containers/models';
import { IInventoryLocation } from '@/features/Location/models';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

type State = {
  container: IInventoryContainer | null;
  containerType: IInventoryContainerType | null;
  location: IInventoryLocation | null;
};

type Actions = {
  setContainer: (container: IInventoryContainer) => void;
  setContainerType: (params: IInventoryContainerType) => void;
  setLocation: (location: IInventoryLocation) => void;
  resetContainer: () => void;
  resetContainerType: () => void;
  resetLocation: () => void;
  reset: () => void;
};

const initialValues: State = {
  container: null,
  containerType: null,
  location: null
};

export const useContainersStore = create(
  devtools(
    persist<State & Actions>(
      (set) => ({
        ...initialValues,
        setContainer: (container) => set(() => ({ container })),
        setContainerType: (containerType) => set(() => ({ containerType })),
        setLocation: (location) => set(() => ({ location })),
        resetContainer: () => set(() => ({ container: null })),
        resetContainerType: () => set(() => ({ containerType: null })),
        resetLocation: () => set(() => ({ location: null })),
        reset: () => set(() => ({ container: null, containerType: null }))
      }),
      {
        name: 'containers',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
);
