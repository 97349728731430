import { Notification } from '@/features/Notifications/models';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

type State = {
  notification: Notification;
};

type Actions = {
  setNotification: (notification: Notification) => void;
  reset: () => void;
};

const initialValues: State = {
  notification: { type: null, message: '' }
};

export const useNotificationsStore = create(
  devtools(
    persist<State & Actions>(
      (set) => ({
        ...initialValues,
        notification: { ...initialValues.notification },
        setNotification: (notification) => set(() => ({ notification })),
        reset: () =>
          set(() => ({ ...initialValues, notification: { ...initialValues.notification } }))
      }),
      {
        name: 'notifications',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
);
