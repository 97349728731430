import Button from '@/common/components/Button/Button';
import { Link, isRouteErrorResponse, useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <>
      <header className='flex flex-1 flex-col items-center justify-center'>
        <h1>Oops!</h1>
        <p className='mb-5'>Sorry, an unexpected error has occurred.</p>
        {isRouteErrorResponse(error) && (
          <p>
            <i>{`${error.status} - ${error.statusText}`}</i>
          </p>
        )}
      </header>
      <div className='buttons-wrapper'>
        {isRouteErrorResponse(error) ? (
          <Link to='..' className='link-button bg-Blue-Base' relative='path'>
            Back
          </Link>
        ) : (
          <Button
            color='primary'
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </Button>
        )}
      </div>
    </>
  );
}
