import { IUser } from '@/features/MainMenu/models';
import { includes, some } from 'lodash';

export const Roles = {
  MobileLogTime: 'Mobile Log Time'
};

export const hasRoles = (user?: IUser | null, roles: string[] = []) => {
  return some(user?.employee?.roles || [], (r) => includes(roles, r.name));
};
