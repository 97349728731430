import Loading from '@/common/components/Loading/Loading';
import { Roles, hasRoles } from '@/common/utils/user';
import { useBarcodeStore } from '@/store/barcode';
import { useNotificationsStore } from '@/store/notifications';
import { useSelectedProjectStore } from '@/store/project';
import { useTempUserStore, useUserStore } from '@/store/user';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useBarcodeSettingList, useLoggedUserInfo } from '../../MainMenu/hooks';
import UserInfo from './UserInfo';

export default function RootLayout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, setUser } = useUserStore();
  const { reset: resetTempUser } = useTempUserStore();
  const { reset: resetProject } = useSelectedProjectStore();
  const { barcodeSettings, setBarcodeSettings } = useBarcodeStore();
  const { setNotification } = useNotificationsStore();

  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError
  } = useLoggedUserInfo({
    isEnabled: !user?.userId
  });
  const {
    data: barcodeSettingsData,
    isLoading: barcodeSettingsIsLoading,
    isError: barcodeSettingsIsError
  } = useBarcodeSettingList({
    isEnabled: !barcodeSettings
  });

  useEffect(() => {
    if (user) {
      if (hasRoles(user, [Roles.MobileLogTime])) {
        if (pathname === '/time-tracker/login') {
          resetTempUser();
          resetProject();
        }
        if (user.employee.roles.length === 1 && !pathname.startsWith('/time-tracker/')) {
          navigate('/time-tracker/login');
        }
      }
    }
  }, [navigate, pathname, resetProject, resetTempUser, user]);

  useEffect(() => {
    if (userIsLoading) return;

    if (userData) {
      setUser(userData);
    }

    if (userIsError) {
      setNotification({ message: "Can't load user data", type: 'Error' });
    }
  }, [setNotification, setUser, userData, userIsError, userIsLoading]);

  useEffect(() => {
    if (barcodeSettingsIsLoading) return;

    if (barcodeSettingsData?.items) {
      setBarcodeSettings(barcodeSettingsData.items);
    }

    if (barcodeSettingsIsError) {
      setNotification({ message: "Can't load barcode settings data", type: 'Error' });
    }
  }, [
    barcodeSettingsData?.items,
    barcodeSettingsIsError,
    barcodeSettingsIsLoading,
    setBarcodeSettings,
    setNotification
  ]);

  if (userIsLoading || barcodeSettingsIsLoading) {
    return (
      <div className='flex flex-1 items-center justify-center'>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <UserInfo user={user} />
      <main className='flex flex-1 flex-col justify-between gap-5 overflow-auto'>
        <Outlet />
      </main>
    </>
  );
}
