import getWithToken from '@/api/base/get-with-token';
import { useQuery } from '@tanstack/react-query';
import { IUser } from '../models';

export const useLoggedUserInfo = ({
  employeeId,
  employeeCode,
  isEnabled
}: {
  employeeId?: string;
  employeeCode?: string;
  isEnabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['loggedUserInfo', employeeId, employeeCode],
    queryFn: async () => {
      const response = await getWithToken('/api/logged-user-info', { employeeId, employeeCode });
      return response.data as IUser;
    },
    enabled: isEnabled
  });
};
