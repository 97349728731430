import { NOTIFICATION_TYPE } from '@/features/Notifications/constants';
import { NotificationType as NotificationTypeModel } from '@/features/Notifications/models';
import { useEffect } from 'react';
import ModalWrapper from './ModalWrapper';

interface IProps {
  type: keyof typeof NOTIFICATION_TYPE;
  children: React.ReactNode;
  timeout?: number;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function NotificationModal({
  type,
  children,
  timeout = 2000,
  isVisible,
  setIsVisible
}: IProps) {
  const colorScheme: { [key in NotificationTypeModel]: string } = {
    error: 'bg-Red-Base text-white z-50',
    success: 'bg-Green-Base text-white z-50'
  };

  useEffect(() => {
    if (!isVisible) return;

    const timer = setTimeout(() => {
      setIsVisible(false);
    }, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [isVisible, setIsVisible, timeout]);

  if (!isVisible) return null;

  return (
    <ModalWrapper
      containerId='notifications'
      onClick={() => {
        setIsVisible(false);
      }}
      className={colorScheme[NOTIFICATION_TYPE[type]]}
      role='alert'
    >
      {children}
    </ModalWrapper>
  );
}
