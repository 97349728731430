import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL_BASE ?? '',
  timeout: 10000
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.pathname = '/logout';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
