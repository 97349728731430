import Button from '@/common/components/Button/Button';
import ConfirmationModal from '@/common/components/Modals/ConfirmationModal';

interface IProps {
  onSubmit: () => void;
  onCancel: () => void;
}

export default function LogoutModal({ onCancel, onSubmit }: IProps) {
  return (
    <ConfirmationModal>
      <h1>Logout</h1>
      <p>Are you sure you want to log out?</p>
      <div className='flex w-full gap-5'>
        <Button color='secondary' onClick={onCancel}>
          Cancel
        </Button>
        <Button color='success' onClick={onSubmit}>
          Yes
        </Button>
      </div>
    </ConfirmationModal>
  );
}
