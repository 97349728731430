import { RouteObject } from 'react-router-dom';

export const countRoutes: RouteObject[] = [
  {
    path: 'count',
    lazy: () => import('../pages/count')
  },
  {
    path: 'count/:countId',
    lazy: () => import('../pages/count/[countId]')
  },
  // COUNT > ADD COUNT
  {
    path: 'count/:countId/add-to-count',
    lazy: () => import('../pages/count/[countId]/add-to-count')
  },
  {
    path: 'count/:countId/add-to-count/location',
    lazy: () => import('../pages/count/[countId]/add-to-count/location')
  },
  {
    path: 'count/:countId/add-to-count/inventory-item',
    lazy: () => import('../pages/count/[countId]/add-to-count/inventory-item')
  },
  {
    path: 'count/:countId/add-to-count/inventory-item',
    lazy: () => import('../pages/count/[countId]/add-to-count/inventory-item')
  },
  {
    path: 'count/:countId/add-to-count/inventory-item/category',
    lazy: () => import('../pages/count/[countId]/add-to-count/inventory-item/category')
  },
  // COUNT > EDIT COUNT
  {
    path: 'count/:countId/edit-count',
    lazy: () => import('../pages/count/[countId]/edit-count')
  }
];
