import { Roles, hasRoles } from '@/common/utils/user';
import { IUser } from '@/features/MainMenu/models';
import { useTempUserStore } from '@/store/user';
import { some } from 'lodash';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const AllowedPaths = [
  '/time-tracker/dashboard',
  '/time-tracker/select-project',
  '/time-tracker/select-task'
];

interface IProps {
  user: IUser | null;
}

export default function UserInfo({ user }: IProps) {
  const { pathname } = useLocation();
  const { user: tempUser } = useTempUserStore();
  const userTitle = useMemo(() => {
    if (
      hasRoles(user, [Roles.MobileLogTime]) &&
      some(AllowedPaths, (path) => pathname.startsWith(path))
    ) {
      return tempUser
        ? `${tempUser.employee.firstName} ${tempUser.employee.lastName} (${tempUser.employee.employeeCode})`
        : '';
    } else {
      return user
        ? `${user.employee.firstName} ${user.employee.lastName} (${user.employee.employeeCode})`
        : '';
    }
  }, [pathname, tempUser, user]);

  return pathname !== '/time-tracker/login' ? (
    <header className='fixed left-0 top-0 w-full bg-black p-[1px] text-center text-sm text-white'>
      <p>{userTitle}</p>
    </header>
  ) : null;
}
