import { IInventoryCategory } from '@/features/CategorySelection/models';
import { IInventoryCount } from '@/features/Count/models';
import { IInventoryItem, IInventoryItemDropDown } from '@/features/ItemSelection/models';
import { IInventoryLocation } from '@/features/Location/models';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

type State = {
  count: IInventoryCount | null;
  location: IInventoryLocation | null;
  category: IInventoryCategory | null;
  inventoryItem: IInventoryItemDropDown | IInventoryItem | null;
  quantity: number | null;
};

type Actions = {
  setCount: (count: IInventoryCount) => void;
  setLocation: (location: IInventoryLocation) => void;
  setCategory: (category: IInventoryCategory) => void;
  setInventoryItem: (inventoryItem: IInventoryItemDropDown | IInventoryItem) => void;
  setQuantity: (quantity: number) => void;
  resetCount: () => void;
  resetLocation: () => void;
  resetCategory: () => void;
  resetInventoryItem: () => void;
  resetQuantity: () => void;
  reset: () => void;
};

const initialValues: State = {
  count: null,
  location: null,
  category: null,
  inventoryItem: null,
  quantity: null
};

export const useCountStore = create(
  devtools(
    persist<State & Actions>(
      (set) => ({
        ...initialValues,
        setCount: (count) => set(() => ({ count })),
        setLocation: (location) => set(() => ({ location })),
        setCategory: (category) => set(() => ({ category })),
        setInventoryItem: (inventoryItem) => set(() => ({ inventoryItem })),
        setQuantity: (quantity) => set(() => ({ quantity })),
        resetCount: () => set(() => ({ count: null })),
        resetLocation: () => set(() => ({ location: null })),
        resetCategory: () => set(() => ({ category: null })),
        resetInventoryItem: () => set(() => ({ inventoryItem: null })),
        resetQuantity: () => set(() => ({ quantity: null })),
        reset: () => set(() => ({ inventoryItem: null, quantity: null }))
      }),
      {
        name: 'count',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
);
