import { includes } from 'lodash';
import onScan from 'onscan.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import WithOidc from './WithOidc.tsx';
import './assets/css/scrollbar.css';
import './index.css';

onScan.attachTo(document, {
  minLength: 3,
  timeBeforeScanTest: 320,
  avgTimeByChar: 50,
  keyCodeMapper: function (oEvent) {
    console.log(oEvent);
    const allowedKeys = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_~:+';
    if (includes(allowedKeys, oEvent.key)) {
      return oEvent.key;
    }
    return onScan.decodeKeyEvent(oEvent);
  },
  onScanError(debug) {
    console.log(debug);
  }
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <WithOidc>
      <App />
    </WithOidc>
  </React.StrictMode>
);
