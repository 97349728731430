import { RouteObject } from 'react-router-dom';

export const itemsRoutes: RouteObject[] = [
  {
    path: 'items',
    lazy: () => import('../pages/items')
  },
  //   ITEMS > ADD ITEM
  {
    path: 'items/add-item',
    lazy: () => import('../pages/items/add-item')
  },
  {
    path: 'items/add-item/inventory-item',
    lazy: () => import('../pages/items/add-item/inventory-item')
  },
  {
    path: 'items/add-item/inventory-item/category',
    lazy: () => import('../pages/items/add-item/inventory-item/category')
  },
  {
    path: 'items/add-item/container-id',
    lazy: () => import('../pages/items/add-item/container-id')
  },
  {
    path: 'items/add-item/new-container',
    lazy: () => import('../pages/items/add-item/new-container')
  },
  {
    path: 'items/add-item/new-container/container-type',
    lazy: () => import('../pages/items/add-item/new-container/container-type')
  },
  {
    path: 'items/add-item/new-container/location',
    lazy: () => import('../pages/items/add-item/new-container/location')
  },
  //   ITEMS > REMOVE ITEM
  {
    path: 'items/remove-item',
    lazy: () => import('../pages/items/remove-item')
  },
  {
    path: 'items/remove-item/inventory-item',
    lazy: () => import('../pages/items/remove-item/inventory-item')
  },
  {
    path: 'items/remove-item/inventory-item/category',
    lazy: () => import('../pages/items/remove-item/inventory-item/category')
  },
  {
    path: 'items/remove-item/container-id',
    lazy: () => import('../pages/items/remove-item/container-id')
  },
  //   ITEMS > RELOCATE ITEM
  {
    path: 'items/relocate-item',
    lazy: () => import('../pages/items/relocate-item')
  },
  {
    path: 'items/relocate-item/inventory-item',
    lazy: () => import('../pages/items/relocate-item/inventory-item')
  },
  {
    path: 'items/relocate-item/inventory-item/category',
    lazy: () => import('../pages/items/relocate-item/inventory-item/category')
  },
  {
    path: 'items/relocate-item/from-container-id',
    lazy: () => import('../pages/items/relocate-item/from-container-id')
  },
  {
    path: 'items/relocate-item/to-container-id',
    lazy: () => import('../pages/items/relocate-item/to-container-id')
  },
  {
    path: 'items/relocate-item/new-container',
    lazy: () => import('../pages/items/relocate-item/new-container')
  },
  {
    path: 'items/relocate-item/new-container/container-type',
    lazy: () => import('../pages/items/relocate-item/new-container/container-type')
  },
  {
    path: 'items/relocate-item/new-container/location',
    lazy: () => import('../pages/items/relocate-item/new-container/location')
  },
  //   ITEMS > SEARCH ITEM
  {
    path: 'items/search-item',
    lazy: () => import('../pages/items/search-item')
  },
  {
    path: 'items/search-item/category',
    lazy: () => import('../pages/items/search-item/category')
  },
  {
    path: 'items/search-item/results',
    lazy: () => import('../pages/items/search-item/results')
  },
  // ITEMS > RECEIVE ITEM
  {
    path: 'items/receive-item',
    lazy: () => import('../pages/items/receive-item')
  },
  {
    path: 'items/receive-item/:purchaseOrderId',
    lazy: () => import('../pages/items/receive-item/[purchaseOrderId]')
  },
  {
    path: 'items/receive-item/:purchaseOrderId/:itemId',
    lazy: () => import('../pages/items/receive-item/[purchaseOrderId]/[itemId]')
  }
];
