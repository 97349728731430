import { IconType } from '../../models';

interface IProps {
  type: IconType;
  fill?:
    | 'white'
    | 'black'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'gray'
    | 'blue-50'
    | 'gray-30'
    | 'gray-90';
  className?: string;
}

export default function Icons({ type, fill = 'black', className }: IProps) {
  const fillColor = {
    white: 'fill-white',
    black: 'fill-black',
    primary: 'fill-Blue-Base',
    secondary: 'fill-Bluegray-Color',
    success: 'fill-Green-Base',
    error: 'fill-Red-Base',
    gray: 'fill-gray',
    'blue-50': 'fill-blue-50',
    'gray-30': 'fill-gray-30',
    'gray-90': 'fill-gray-90'
  };

  const icons: { [key in IconType]: React.JSX.Element } = {
    'arrow-right': (
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        xmlns='http://www.w3.org/2000/svg'
        className={`${fillColor[fill]} ${className}`}
      >
        <path d='M0 6.92V8.92H12L6.5 14.42L7.92 15.84L15.84 7.92L7.92 -7.62939e-08L6.5 1.42L12 6.92H0Z' />
      </svg>
    ),
    'dots-horizontal': (
      <svg
        width='16'
        height='4'
        viewBox='0 0 16 4'
        xmlns='http://www.w3.org/2000/svg'
        className={`${fillColor[fill]} ${className}`}
      >
        <path d='M12 2C12 1.46957 12.2107 0.96086 12.5858 0.585787C12.9609 0.210714 13.4696 0 14 0C14.5304 0 15.0391 0.210714 15.4142 0.585787C15.7893 0.96086 16 1.46957 16 2C16 2.53043 15.7893 3.03914 15.4142 3.41421C15.0391 3.78929 14.5304 4 14 4C13.4696 4 12.9609 3.78929 12.5858 3.41421C12.2107 3.03914 12 2.53043 12 2ZM6 2C6 1.46957 6.21071 0.96086 6.58579 0.585787C6.96086 0.210714 7.46957 0 8 0C8.53043 0 9.03914 0.210714 9.41421 0.585787C9.78929 0.96086 10 1.46957 10 2C10 2.53043 9.78929 3.03914 9.41421 3.41421C9.03914 3.78929 8.53043 4 8 4C7.46957 4 6.96086 3.78929 6.58579 3.41421C6.21071 3.03914 6 2.53043 6 2ZM0 2C0 1.46957 0.210714 0.96086 0.585786 0.585787C0.960859 0.210714 1.46957 0 2 0C2.53043 0 3.03914 0.210714 3.41421 0.585787C3.78929 0.96086 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4C1.46957 4 0.960859 3.78929 0.585786 3.41421C0.210714 3.03914 0 2.53043 0 2Z' />
      </svg>
    ),
    clear: (
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        xmlns='http://www.w3.org/2000/svg'
        className={`${fillColor[fill]} ${className}`}
      >
        <path d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z' />
      </svg>
    ),
    lock: (
      <svg
        width='16'
        height='21'
        viewBox='0 0 16 21'
        xmlns='http://www.w3.org/2000/svg'
        className={`${fillColor[fill]} ${className}`}
      >
        <path d='M8 16C8.53043 16 9.03914 15.7893 9.41421 15.4142C9.78929 15.0391 10 14.5304 10 14C10 12.89 9.1 12 8 12C7.46957 12 6.96086 12.2107 6.58579 12.5858C6.21071 12.9609 6 13.4696 6 14C6 14.5304 6.21071 15.0391 6.58579 15.4142C6.96086 15.7893 7.46957 16 8 16ZM14 7C14.5304 7 15.0391 7.21071 15.4142 7.58579C15.7893 7.96086 16 8.46957 16 9V19C16 19.5304 15.7893 20.0391 15.4142 20.4142C15.0391 20.7893 14.5304 21 14 21H2C1.46957 21 0.960859 20.7893 0.585786 20.4142C0.210714 20.0391 0 19.5304 0 19V9C0 7.89 0.9 7 2 7H3V5C3 3.67392 3.52678 2.40215 4.46447 1.46447C5.40215 0.526784 6.67392 0 8 0C8.65661 0 9.30679 0.129329 9.91342 0.380602C10.52 0.631876 11.0712 1.00017 11.5355 1.46447C11.9998 1.92876 12.3681 2.47995 12.6194 3.08658C12.8707 3.69321 13 4.34339 13 5V7H14ZM8 2C7.20435 2 6.44129 2.31607 5.87868 2.87868C5.31607 3.44129 5 4.20435 5 5V7H11V5C11 4.20435 10.6839 3.44129 10.1213 2.87868C9.55871 2.31607 8.79565 2 8 2Z' />
      </svg>
    ),
    scan: (
      <svg
        width='24'
        height='20'
        viewBox='0 0 24 20'
        xmlns='http://www.w3.org/2000/svg'
        className={`${fillColor[fill]} ${className}`}
      >
        <path d='M2 2H6V0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 6H2V2ZM22 0H18V2H22V6H24V2C24 1.46957 23.7893 0.960859 23.4142 0.585786C23.0391 0.210714 22.5304 0 22 0ZM2 14H0V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H6V18H2V14ZM22 18H18V20H22C22.5304 20 23.0391 19.7893 23.4142 19.4142C23.7893 19.0391 24 18.5304 24 18V14H22V18ZM4 6V14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6ZM6 14V10H18V14H6ZM18 6V8H6V6H18Z' />
      </svg>
    )
  };

  return icons[type];
}
