import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import { useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { createErrorMessage } from './common/utils';
import Notifications from './features/Notifications/components/Notifications';
import RootLayout from './features/RootLayout/components/RootLayout';
import HomePage from './pages';
import ErrorPage from './pages/error-page';
import LogoutPage from './pages/logout';
import {
  containersRoutes,
  countRoutes,
  cratesRoutes,
  itemsRoutes,
  timeTrackerRoutes
} from './routes';
import { useNotificationsStore } from './store/notifications';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />
      },
      ...countRoutes,
      ...itemsRoutes,
      ...containersRoutes,
      ...cratesRoutes,
      ...timeTrackerRoutes
    ]
  },
  {
    path: 'logout',
    element: <LogoutPage />
  }
]);

export default function App() {
  const { setNotification } = useNotificationsStore();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error, query) => {
            if (
              (query.queryKey?.[0] === 'inventoryContainerId' ||
                query.queryKey?.[0] === 'crateId') &&
              axios.isAxiosError(error) &&
              error.response?.status === 404
            ) {
              return;
            }

            if (query.state.data === undefined) {
              setNotification(createErrorMessage(error));
            }
          }
        })
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
      <Notifications />
    </QueryClientProvider>
  );
}
