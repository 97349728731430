import { PropsWithChildren } from 'react';
import { AuthProvider } from './auth';

const oidcConfig = {
  authority: import.meta.env.VITE_IODC_AUTHORITY,
  clientId: import.meta.env.VITE_IODC_CLIENT_ID,
  redirectUri: window.location.href,
  postLogoutRedirectUri: import.meta.env.VITE_IODC_POST_LOGOUT_REDIRECT_URI,
  extraQueryParams: { __tenant: import.meta.env.VITE_IODC_TENANT },
  loadUserInfo: false,
  responseType: 'code',
  scope: import.meta.env.VITE_IODC_SCOPE
};

export default function WithOidc({ children }: PropsWithChildren) {
  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
}
