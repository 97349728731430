import { AxiosRequestConfig } from 'axios';
import apiGetRequest from './api-get-request';
import getAuthHeader from './get-auth-header';

const getWithToken = (apiPath: string, params?: any, config?: AxiosRequestConfig) =>
  apiGetRequest(apiPath, {
    params: params ?? {},
    ...(config ?? {}),
    headers: getAuthHeader()
  });

export default getWithToken;
