import getWithToken from '@/api/base/get-with-token';
import { useQuery } from '@tanstack/react-query';
import { IBarcodeSettings } from '../models';

interface IParams {
  entity?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  disablePagination?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  isEnabled?: boolean;
}

export const useBarcodeSettingList = ({
  entity,
  disablePagination,
  sorting,
  skipCount,
  maxResultCount,
  isEnabled
}: IParams = {}) => {
  return useQuery({
    queryKey: ['barcodeSettingsList'],
    queryFn: async ({ signal }) => {
      const response = await getWithToken(
        '/api/barcode-settings',
        {
          Entity: entity,
          DisablePagination: disablePagination,
          Sorting: sorting,
          SkipCount: skipCount,
          MaxResultCount: maxResultCount
        },
        { signal }
      );
      return response.data as { items: IBarcodeSettings[]; totalCount: number };
    },
    enabled: isEnabled
  });
};
