import { compose, find, keys, startsWith } from 'lodash/fp';

const getAuthHeader = (): { [key: string]: string } => {
  const key = compose(find(startsWith('oidc.user:')), keys)(localStorage) ?? '';

  const token = localStorage[key] ? JSON.parse(localStorage[key]).access_token : '';

  return {
    Authorization: 'Bearer ' + token
  };
};

export default getAuthHeader;
