import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

type State = {
  project: { id: string; projectNumber: string; projectName: string } | null;
};

type Actions = {
  setProject: (project: { id: string; projectNumber: string; projectName: string }) => void;
  reset: () => void;
};

const initialValues: State = {
  project: null
};

export const useSelectedProjectStore = create(
  devtools(
    persist<State & Actions>(
      (set) => ({
        ...initialValues,
        setProject: (project) => set(() => ({ project })),
        reset: () => set(() => ({ ...initialValues }))
      }),
      {
        name: 'selectedProject',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
);
