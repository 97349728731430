import { createPortal } from 'react-dom';

interface IProps {
  containerId: string;
  className?: string;
  children: React.ReactNode;
  role?: React.AriaRole;
  onClick?: () => void;
}

export default function ModalWrapper({
  containerId,
  className,
  children,
  role = 'dialog',
  ...rest
}: IProps) {
  const containerElement = document.getElementById(containerId) || document.body;

  return (
    <>
      {createPortal(
        <div
          className={`absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center p-5 ${
            className || ''
          }`}
          role={role}
          {...rest}
        >
          {children}
        </div>,
        containerElement
      )}
    </>
  );
}
