import { IBarcodeSettings } from '@/features/MainMenu/models';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

type State = {
  barcodeSettings: IBarcodeSettings[] | null;
};

type Actions = {
  setBarcodeSettings: (barcodeSettings: IBarcodeSettings[]) => void;
  reset: () => void;
};

const initialValues: State = {
  barcodeSettings: null
};

export const useBarcodeStore = create(
  devtools(
    persist<State & Actions>(
      (set) => ({
        ...initialValues,
        setBarcodeSettings: (barcodeSettings) => set(() => ({ barcodeSettings })),
        reset: () => set(() => ({ ...initialValues }))
      }),
      {
        name: 'barcodeSettings',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
);
