import { RouteObject } from 'react-router-dom';

export const containersRoutes: RouteObject[] = [
  {
    path: 'containers',
    lazy: () => import('../pages/containers')
  },
  //   CONTAINERS > ADD CONTAINER
  {
    path: 'containers/add-container',
    lazy: () => import('../pages/containers/add-container')
  },
  {
    path: 'containers/add-container/container-id',
    lazy: () => import('../pages/containers/add-container/container-id')
  },
  {
    path: 'containers/add-container/location',
    lazy: () => import('../pages/containers/add-container/location')
  },
  {
    path: 'containers/add-container/new-container',
    lazy: () => import('../pages/containers/add-container/new-container')
  },
  {
    path: 'containers/add-container/new-container/container-type',
    lazy: () => import('../pages/containers/add-container/new-container/container-type')
  },
  {
    path: 'containers/add-container/new-container/location',
    lazy: () => import('../pages/containers/add-container/new-container/location')
  },
  // CONTAINERS > REMOVE CONTAINER
  {
    path: 'containers/remove-container',
    lazy: () => import('../pages/containers/remove-container')
  },
  {
    path: 'containers/remove-container/container-id',
    lazy: () => import('../pages/containers/remove-container/container-id')
  },
  // CONTAINERS > RELOCATE CONTAINER
  {
    path: 'containers/relocate-container',
    lazy: () => import('../pages/containers/relocate-container')
  },
  {
    path: 'containers/relocate-container/container-id',
    lazy: () => import('../pages/containers/relocate-container/container-id')
  },
  {
    path: 'containers/relocate-container/to-location',
    lazy: () => import('../pages/containers/relocate-container/to-location')
  }
];
