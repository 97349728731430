import { RouteObject } from 'react-router-dom';

export const timeTrackerRoutes: RouteObject[] = [
  { path: 'time-tracker/login', lazy: () => import('../pages/time-tracker/login') },
  { path: 'time-tracker/dashboard', lazy: () => import('../pages/time-tracker/dashboard') },
  {
    path: 'time-tracker/select-project',
    lazy: () => import('../pages/time-tracker/select-project')
  },
  {
    path: 'time-tracker/select-task',
    lazy: () => import('../pages/time-tracker/select-task')
  }
];
