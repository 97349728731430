import { useAuth } from '@/auth';
import { AuthContextProps } from '@/auth/AuthContextInterface';
import Loading from '@/common/components/Loading/Loading';
import { useEffect } from 'react';

export default function LogoutPage() {
  const auth: AuthContextProps = useAuth();

  useEffect(() => {
    sessionStorage.clear();

    const timeout = setTimeout(() => {
      auth.signOutRedirect();
    }, 333);

    return () => {
      clearTimeout(timeout);
    };
  }, [auth]);

  return <Loading />;
}
