interface IProps {
  title: React.ReactNode;
  description?: React.ReactNode;
}

export default function PageHeader({ title, description }: IProps) {
  return (
    <header className='text-center'>
      <h1>{title}</h1>
      {description && <p>{description}</p>}
    </header>
  );
}
