import Loading from '@/common/components/Loading/Loading';
import { User, UserManager, WebStorageStateStore } from 'oidc-client';
import React, { FC, useEffect, useRef, useState } from 'react';
import { AuthContextProps, AuthProviderProps, Location } from './AuthContextInterface';

export const AuthContext = React.createContext<AuthContextProps | undefined>(undefined);

const hasCodeInUrl = (location: Location): boolean => {
  const searchParams = new URLSearchParams(location.search);
  const hashParams = new URLSearchParams(location.hash.replace('#', '?'));

  return Boolean(
    searchParams.get('code') ||
      searchParams.get('id_token') ||
      searchParams.get('session_state') ||
      hashParams.get('scope') ||
      hashParams.get('state') ||
      hashParams.get('id_token') ||
      hashParams.get('session_state')
  );
};

const removeCodeInUrl = () => {
  const href = new URL(window.location.href);

  href.searchParams.delete('code');
  href.searchParams.delete('id_token');
  href.searchParams.delete('session_state');
  href.searchParams.delete('scope');
  href.searchParams.delete('state');
  href.searchParams.delete('id_token');
  href.searchParams.delete('session_state');

  window.location.href = href.toString();
};

interface AuthProviderPropsEx extends AuthProviderProps {
  extraQueryParams: any;
  [key: string]: any;
}

const initUserManager = (props: AuthProviderPropsEx): UserManager => {
  if (props.userManager) return props.userManager;
  const {
    authority,
    clientId,
    clientSecret,
    redirectUri,
    silentRedirectUri,
    postLogoutRedirectUri,
    responseType,
    scope,
    automaticSilentRenew,
    loadUserInfo,
    popupWindowFeatures,
    popupRedirectUri,
    popupWindowTarget,
    extraQueryParams
  } = props;
  return new UserManager({
    authority,
    client_id: clientId,
    client_secret: clientSecret,
    redirect_uri: redirectUri,
    silent_redirect_uri: silentRedirectUri || redirectUri,
    post_logout_redirect_uri: postLogoutRedirectUri || redirectUri,
    response_type: responseType || 'code',
    scope: scope || 'openid',
    loadUserInfo: loadUserInfo !== undefined ? loadUserInfo : true,
    popupWindowFeatures: popupWindowFeatures,
    popup_redirect_uri: popupRedirectUri,
    popupWindowTarget: popupWindowTarget,
    automaticSilentRenew,
    extraQueryParams,
    userStore: new WebStorageStateStore({ store: localStorage })
  });
};

export const AuthProvider: FC<AuthProviderPropsEx> = ({
  children,
  autoSignIn = true,
  onBeforeSignIn,
  onSignIn,
  onSignOut,
  location = window.location,
  ...restOfProps
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<User | null>(null);
  const [userManager] = useState<UserManager>(() => initUserManager(restOfProps));

  const signOutHooks = async (): Promise<void> => {
    setUserData(null);
    onSignOut && onSignOut();
  };
  const signInPopupHooks = async (): Promise<void> => {
    const userFromPopup = await userManager.signinPopup();
    setUserData(userFromPopup);
    onSignIn && onSignIn(userFromPopup);
    await userManager.signinPopupCallback();
  };

  const isMountedRef = useRef(true);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const getUser = async (): Promise<void> => {
      try {
        if (hasCodeInUrl(location)) {
          const user = await userManager.signinCallback();
          setUserData(user);
          setIsLoading(false);

          if (!user) {
            onSignIn && onSignIn(user);
          } else {
            removeCodeInUrl();
          }
          return;
        }

        const user = await userManager.getUser();
        if ((!user || user.expired) && autoSignIn) {
          onBeforeSignIn && onBeforeSignIn();
          await userManager.signinRedirect();
        } else if (isMountedRef.current) {
          setUserData(user);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error('error', error);
      }
    };
    getUser();
  }, [autoSignIn, onBeforeSignIn, onSignIn, location, userManager]);

  useEffect(() => {
    // for refreshing react state when new state is available in e.g. session storage
    const updateUserData = async () => {
      const user = await userManager.getUser();
      isMountedRef.current && setUserData(user);
    };

    userManager.events.addUserLoaded(updateUserData);

    return () => userManager.events.removeUserLoaded(updateUserData);
  }, [userManager]);

  return (
    <AuthContext.Provider
      value={{
        signIn: async (args: unknown): Promise<void> => {
          await userManager.signinRedirect(args);
        },
        signInPopup: async (): Promise<void> => {
          await signInPopupHooks();
        },
        signOut: async (): Promise<void> => {
          await userManager.removeUser();
          await signOutHooks();
        },
        signOutRedirect: async (): Promise<void> => {
          const user = await userManager.getUser();
          if (user) {
            const idTokenHint = user.id_token;
            await userManager.signoutRedirect({ id_token_hint: idTokenHint });
          }
        },
        userManager,
        userData,
        isLoading
      }}
    >
      {isLoading || !userData ? <Loading /> : children}
    </AuthContext.Provider>
  );
};
