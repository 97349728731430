import { forwardRef } from 'react';
import { IconType } from '../../models';
import Icons from '../Icons/Icons';

interface IProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'orange';
  children: React.ReactNode;
  icon?: IconType;
}

const Button = forwardRef<HTMLButtonElement, IProps>(function Button(
  { color = 'primary', children, disabled, icon, ...rest },
  ref
) {
  const colorScheme: { [key in typeof color]: string } = {
    primary: 'bg-Blue-Base text-white',
    secondary: 'bg-Bluegray-Color text-white',
    success: 'bg-Green-Base text-white',
    error: 'bg-Red-Base text-white',
    orange: 'bg-[#F3AA18] text-white'
  };

  return (
    <button
      type='button'
      {...rest}
      ref={ref}
      className={`rounded ${colorScheme[color]} p-3 text-lg uppercase ${
        disabled ? 'opacity-25' : 'hover:cursor-pointer hover:opacity-80'
      } ${icon ? 'flex items-center justify-between' : ''} flex-1 font-medium`}
      disabled={disabled}
    >
      {children}
      {icon && <Icons type={icon} fill='white' />}
    </button>
  );
});

export default Button;
