import { IUser } from '@/features/MainMenu/models';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

type State = {
  user: IUser | null;
};

type Actions = {
  setUser: (user: IUser) => void;
  reset: () => void;
};

const initialValues: State = {
  user: null
};

export const useUserStore = create(
  devtools(
    persist<State & Actions>(
      (set) => ({
        ...initialValues,
        setUser: (user) => set(() => ({ user })),
        reset: () => set(() => ({ ...initialValues }))
      }),
      {
        name: 'user',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
);

export const useTempUserStore = create(
  devtools(
    persist<State & Actions>(
      (set) => ({
        ...initialValues,
        setUser: (user) => set(() => ({ user })),
        reset: () => set(() => ({ ...initialValues }))
      }),
      {
        name: 'tempUser',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
);
