import { RouteObject } from 'react-router-dom';

export const cratesRoutes: RouteObject[] = [
  {
    path: 'crates',
    lazy: () => import('../pages/crates')
  },
  //   CRATES > EDIT CRATE
  {
    path: 'crates/edit-crate',
    lazy: () => import('../pages/crates/edit-crate')
  },
  {
    path: 'crates/edit-crate/:crateId',
    lazy: () => import('../pages/crates/edit-crate/[crateId]')
  },
  {
    path: 'crates/edit-crate/:crateId/edit-status',
    lazy: () => import('../pages/crates/edit-crate/[crateId]/edit-status')
  },
  {
    path: 'crates/edit-crate/:crateId/additional-info',
    lazy: () => import('../pages/crates/edit-crate/[crateId]/additional-info')
  },
  {
    path: 'crates/edit-crate/:crateId/shipping-list',
    lazy: () => import('../pages/crates/edit-crate/[crateId]/shipping-list')
  },
  // CRATES > ADD / REMOVE PRODUCTS
  {
    path: 'crates/add-remove-products',
    lazy: () => import('../pages/crates/add-remove-products')
  },
  {
    path: 'crates/add-remove-products/new-crate',
    lazy: () => import('../pages/crates/add-remove-products/new-crate')
  },
  {
    path: 'crates/add-remove-products/new-crate/shipping-list',
    lazy: () => import('../pages/crates/add-remove-products/new-crate/shipping-list')
  },
  {
    path: 'crates/add-remove-products/link-crate-to-shipping-list',
    lazy: () => import('../pages/crates/add-remove-products/link-crate-to-shipping-list')
  },
  {
    path: 'crates/add-remove-products/:crateId',
    lazy: () => import('../pages/crates/add-remove-products/[crateId]')
  },
  {
    path: 'crates/add-remove-products/:crateId/scan-operation',
    lazy: () => import('../pages/crates/add-remove-products/[crateId]/scan-operation')
  },
  {
    path: 'crates/add-remove-products/:crateId/edit-product-list',
    lazy: () => import('../pages/crates/add-remove-products/[crateId]/edit-product-list')
  },
  // CRATES > LOAD CRATE
  {
    path: 'crates/load-crate',
    lazy: () => import('../pages/crates/load-crate')
  },
  {
    path: 'crates/load-crate/:crateId',
    lazy: () => import('../pages/crates/load-crate/[crateId]')
  },
  // CRATES > UNLOAD CRATE
  {
    path: 'crates/unload-crate',
    lazy: () => import('../pages/crates/unload-crate')
  },
  {
    path: 'crates/unload-crate/:crateId',
    lazy: () => import('../pages/crates/unload-crate/[crateId]')
  }
];
