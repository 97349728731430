import { PropsWithChildren } from 'react';
import ModalWrapper from './ModalWrapper';

export default function ConfirmationModal({ children }: PropsWithChildren) {
  return (
    <ModalWrapper
      containerId='confirmations'
      className='flex-1 justify-between bg-gray-90 text-white'
    >
      {children}
    </ModalWrapper>
  );
}
